// Если нам надо оплучить глобальные данные
// import { getters } from '@/store/store';
// Если нам надо обработать данные используем мутации
// import { mutations } from './mutations';

export default {
	get(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/GetDynamicAnketingResults', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getIP(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/GetDynamicAnketingByIPAddress', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getMO(parametrs) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/GetDynamicAnketingResults', parametrs)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getDataDiagrams(params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/AnketaGraphicResults', params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	getDataTable(medicalId, params) {
		return new Promise((resolve, reject) => {
			this.$http
				.post(`Anketa/GetStrangeAnkets?medicalId=${medicalId}`, params)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
	deleteStrangeAnkets(listMo) {
		return new Promise((resolve, reject) => {
			this.$http
				.post('Anketa/DeleteStrangeAnkets', listMo)
				.then((res) => {
					resolve(res);
				})
				.catch((error) => {
					reject(error);
				});
		});
	}
};
