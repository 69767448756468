<template>
	<div>
		<div class="flex items-center mt-5">
			<div class="w-3/12 input-box items-center">
				<span class="text-gray-400 mb-2">Показать IP, с которых отправлено:</span>
				<multiselect
					@input="changeIP"
					tag-placeholder=""
					placeholder=""
					v-model="moreVal"
					:hide-selected="true"
					:searchable="false"
					:multiple="false"
					:options="moreNumber"
					label="name"
					track-by="id"
				/>
			</div>
			<div class="flex w-9/12 justify-end items-center text-right">
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="flex flex-col relative">
			<div class="t-overflow rounded-t-lg overflow-x-auto h-auto bg-white filter shadow-default" :class="{ 'custom-w-table-144': !menu, 'custom-w-table-360': menu }">
				<table class="w-full" :style="list.length > 0 ? 'min-height: 300px' : ''">
					<thead>
						<tr>
							<th
								class="px-6 py-3 border-b border-r border-gray-200 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
								v-for="(item, ind) in fields"
								:class="{ 'w-3/12': ind === 3 }"
								:key="'J' + ind"
							>
								<span class="flex relative">
									<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</span>
									<Icons v-if="params.filters.find((f) => f.fieldName === item.fieldName)" icon-name="filter" class="hidden sm:block ml-2" />

									<!-- Фильтр и сортировка -->
									<FilterComponent
										:position="ind === 0 ? 'left' : 'right'"
										v-on:change="switchFilterVisibility(null)"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields"
										:item="item"
									/>
								</span>
							</th>
						</tr>
					</thead>
					<tbody class="bg-white" v-for="(item, index) in list" :key="'G' + index">
						<tr :class="{ 'bg-gray-30': item.openMO }">
							<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								{{ item.ipAddress }}
							</td>
							<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								{{ item.moName }}
							</td>
							<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								{{ item.regionName }}
							</td>
							<td class="px-6 py-4 text-sm font-normal whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500">
								{{ item.count }}
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && list.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<div class="hidden sm:flex flex-row pt-6 pl-6 pr-20 pb-12 text-gray-400 text-sm">
					<div class="mr-auto">
						<p>Всего записей: {{ totalRows }}</p>
					</div>
					<div class="flex flex-row ml-auto">
						<p>Строк на странице:</p>
						<ul class="flex flex-row">
							<li @click="updateRow(50)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 50 }">50</li>
							<li @click="updateRow(100)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 100 }">100</li>
							<li @click="updateRow(200)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == 200 }">200</li>
							<li @click="updateRow(null)" class="flex ml-4 cursor-pointer" :class="{ 'text-blue-500': rowsVisible == null }">Все</li>
						</ul>
					</div>
					<div class="flex flex-row ml-24">
						<ul class="flex flex-row">
							<li class="flex ml-4 cursor-pointer arrow" @click="page !== 0 ? navigation('params', page - 1) : ''" />
							<li class="flex">
								<span>Стр</span>
								<b class="ml-2">{{ page + 1 }}</b>
								<span class="ml-2">из</span>
								<b class="ml-2">{{ totalCount === 0 ? 1 : totalCount }}</b>
							</li>
							<li class="flex ml-2 cursor-pointer arrow" @click="page + 1 < totalCount ? navigation('params', page + 1) : ''" />
						</ul>
					</div>
				</div>
			</div>

			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, mutations } from '@/store/store';
// Локальные данные стора
import actions from '../store/actions';
import FilterComponent from '../../../../components/Filter.vue';
import loadingComponent from '../../../../components/Loading.vue';
import Button from '@/components/Button.vue';
import Multiselect from 'vue-multiselect';
import Icons from '@/components/Icons.vue';

export default {
	name: 'TableRegion',
	props: {
		years: {
			type: Array,
			default: () => []
		},
		quarter: {
			type: Array,
			default: () => []
		},
		month: {
			type: Array,
			default: () => []
		},
		dateFrom: {
			type: String,
			default: () => ''
		},
		dateTo: {
			type: String,
			default: () => ''
		},
		resetYearQuarterMonth: {
			type: Boolean,
			default: () => false
		}
	},
	components: {
		loadingComponent,
		Button,
		Icons,
		FilterComponent,
		Multiselect
	},
	computed: {
		...getters
	},
	watch: {
		years(val) {
			if (val.length > 0) {
				this.addParams(
					'Years',
					val.map((m) => m.year),
					7,
					2
				);
			} else {
				let index = this.params.filters.findIndex((f) => f.fieldName === 'Years');
				if (index >= 0) this.params.filters.splice(index, 1);

				if (!this.resetYearQuarterMonth) this.getData(this.params);
			}
		},
		quarter(val) {
			if (val.length > 0) {
				this.params.columns = val.map((m) => m.quarterEng);
				this.getData(this.params);
			} else {
				let pc = this.params.columns;

				for (let i = 0; i < pc.length; i += 1) {
					if (pc[i] === 'FirstQuarter' || pc[i] === 'SecondQuarter' || pc[i] === 'ThirdQuarter' || pc[i] === 'FourthQuarter') {
						this.params.columns.splice(i, 1);
					}
				}

				if (!this.resetYearQuarterMonth) this.getData(this.params);
			}
		},
		month(val) {
			if (this.resetYearQuarterMonth) return;

			if (val.length > 0) {
				this.params.columns = val.map((m) => m.monthEng);
				this.getData(this.params);
			} else {
				let pc = this.params.columns;

				for (let i = 0; i < pc.length; i += 1) {
					if (
						pc[i] === 'January' ||
						pc[i] === 'February' ||
						pc[i] === 'March' ||
						pc[i] === 'April' ||
						pc[i] === 'May' ||
						pc[i] === 'June' ||
						pc[i] === 'July' ||
						pc[i] === 'August' ||
						pc[i] === 'September' ||
						pc[i] === 'October' ||
						pc[i] === 'November' ||
						pc[i] === 'December'
					) {
						this.params.columns.splice(i, 1);
					}
				}
				if (!this.resetYearQuarterMonth) this.getData(this.params);
			}
		},
		dateFrom(val) {
			if (val) {
				this.filterDate.from = val;
				this.params.columns = ['DateFilter'];
			}
			if (!val) {
				let index = this.params.columns.findIndex((f) => f === 'DateFilter');
				if (index >= 0) this.params.columns.splice(index, 1);

				let index2 = this.params.filters.findIndex((f) => f.fieldName === 'Date');
				if (index2 >= 0) this.params.filters.splice(index2, 1);

				this.openCheckboxForDateFromTo = true;

				this.getData(this.params);
			}
		},
		dateTo(val) {
			if (this.filterDate.from && val) {
				this.addParams('Date', this.filterDate.from, 8, 2, val);
			}
		}
	},
	data() {
		return {
			filterDate: {
				from: null,
				to: null
			},
			moreVal: { id: 3, name: 'Больше 1000', count: '1000' },
			moreNumber: [
				{ id: 1, name: 'Больше 300', count: '300' },
				{ id: 2, name: 'Больше 600', count: '600' },
				{ id: 3, name: 'Больше 1000', count: '1000' }
			],
			loadingTable: false,
			loadToExcell: false,
			rowsVisible: 100,
			totalRows: 0,
			totalCount: 0,
			page: 0,
			filterOpen: null,
			filterBy: null,
			fields: [
				{
					Name: 'IP адрес',
					fieldName: 'IpAddress',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Наименование МО',
					fieldName: 'MoName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Регион',
					fieldName: 'RegionName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Общее количество заполненных интерактивных анкет',
					fieldName: 'Count',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			list: [],
			params: {
				filters: [],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 100,
				currentPage: 0,
				columns: []
			}
		};
	},
	methods: {
		...mutations,
		...actions,
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		updateRow(rowsVisible) {
			this.rowsVisible = rowsVisible;
			this.params.pageSize = rowsVisible;
			this.getData(this.params);
		},
		getData(params) {
			this.loadingTable = true;

			this.getIP({
				filters: [
					...params.filters,
					{
						fieldName: 'Count',
						filterType: 4,
						filterValueType: 1,
						value1: this.moreVal.count
					},
					{
						fieldName: 'RegionId',
						filterType: 8,
						filterValueType: 2,
						value1: this.region.id
					}
				],
				sortOption: {
					fieldName: params.sortOption.fieldName,
					sortType: params.sortOption.sortType
				},
				pageSize: params.pageSize,
				currentPage: params.currentPage,
				columns: [...params.columns, 'RegionCount']
			}).then((res) => {
				this.loadingTable = false;
				this.list = res.data.data;
				this.totalRows = res.data.totalRecords;
				this.totalCount = this.rowsVisible ? Math.ceil(res.data.totalRecords / this.rowsVisible) : 1;
			});
		},
		navigation(name, page) {
			this.page = page;
			this[name].currentPage = page;

			this.getData(this[name]);
		},
		downloadExcell(params) {
			this.loadToExcell = true;

			this.$http
				.post(
					'Anketa/GetDynamicAnketingByIPAddressExcel',
					{
						filters: [
							...params.filters,
							{
								fieldName: 'Count',
								filterType: 4,
								filterValueType: 1,
								value1: this.moreVal.count
							},
							{
								fieldName: 'RegionId',
								filterType: 8,
								filterValueType: 2,
								value1: this.region.id
							}
						],
						sortOption: {
							fieldName: params.sortOption.fieldName,
							sortType: params.sortOption.sortType
						},
						pageSize: params.pageSize,
						currentPage: params.currentPage,
						columns: params.columns
					},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					// let fileNameAndType = decodeURI(res.headers['content-disposition'].split('filename*=')[1].split("''")[1]);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', 'Общее количество заполненных интерактивных анкет.xlsx');
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);

					this.loadToExcell = false;
				})
				.catch((error) => {
					if (error) {
						this.loadToExcell = false;
						this.$toast.error('Ошибка выгрузки, обратитесь к Администратору!', {
							position: 'top-right',
							timeout: 3000,
							closeOnClick: true,
							pauseOnFocusLoss: true,
							pauseOnHover: true,
							draggable: true,
							draggablePercent: 0.6,
							showCloseButtonOnHover: false,
							hideProgressBar: true,
							closeButton: 'button',
							icon: true,
							rtl: false
						});
					}
				});
		},
		addParams(fieldName, value, filterType, filterValueType, value2) {
			let flag = value2 ?? false;
			if (this.params.filters.length > 0) {
				for (let i = 0; i < this.params.filters.length; i += 1) {
					if (this.params.filters[i].fieldName === fieldName) {
						if ((value.length === 1 && value[0] === 0) || value.length === 0) {
							this.params.filters.splice(i, 1);

							this.getData(this.params);
							return;
						}

						this.params.filters[i].value1 = flag ? value : value.toString();
						if (flag) this.params.filters[i].value2 = value2;
						this.getData(this.params);

						return;
					}
				}

				this.params.filters.push({
					fieldName: fieldName,
					filterType: filterType,
					filterValueType: filterValueType,
					value1: flag ? value : value.toString(),
					value2: flag ? value2 : undefined
				});

				this.getData(this.params);
				return;
			}

			if (value.length > 0 || value) {
				this.params.filters.push({
					fieldName: fieldName,
					filterType: filterType,
					filterValueType: filterValueType,
					value1: flag ? value : value.toString(),
					value2: flag ? value2 : undefined
				});
			}

			this.getData(this.params);
		},
		changeIP(e) {
			this.moreVal = e;

			this.getData(this.params);
		}
	}
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.checkbox-w-h-20 {
	width: 20px;
	height: 20px;
}

.btn_default {
	width: 24px;
	height: 24px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px;
	gap: 10px;
}

input[type='date'] {
	background: initial;
	box-sizing: content-box;
	display: block;
	position: relative;
	min-height: 50px;
	text-align: left;
	color: #35495e;
	border: 1px solid gray;
	border-radius: 10px;
	padding-left: 12px;
	padding-right: 4px;
}
.multiselect__tags {
	font-weight: 400;
	font-size: 14px;
	line-height: 50px;
	min-height: 50px;
	width: 100%;
	border-radius: 10px;
	border: 1px solid #a8b1ce;
	padding: 0 20px;
	color: #6a6e83;
	background: initial;
}
.multiselect__tags-wrap {
	display: flex;
	align-items: center;
	height: 50px;
}
.multiselect__option--highlight:after {
	display: none;
}
.multiselect__select {
	height: 50px;
}
.label {
	align-items: center;
	cursor: pointer;
}
.label:hover .label-text {
	text-decoration: none;
}
.label-text {
	text-align: center;
	font-weight: 700;
	font-size: 14px;
	line-height: 18px;
	text-decoration-line: underline;
	color: #3377ff;
}

.input-box {
	position: relative;
	margin-bottom: 20px;
}

.input-box label {
	font-weight: 700;
	font-size: 14px;
	display: block;
	line-height: 18px;
	margin-bottom: 12px;
	color: #a8b1ce;
}

.preloader {
	z-index: 10;
	background: rgba(0, 0, 0, 0.09);
}

.sticky-table__header1 {
	left: 0;
	z-index: 9;
	position: sticky;
	text-align: center;
	background-color: rgba(249, 250, 251);
}
.sticky-table__header2 {
	left: 9.9rem;
	z-index: 8;
	position: sticky;
	text-align: center;
	background-color: rgba(249, 250, 251);
}
.sticky-table__row1 {
	background-color: #fff;
	left: 0;
	position: sticky;
}
.sticky-table__row2 {
	background-color: #fff;
	left: 9.9rem;
	position: sticky;
}

li.arrow::before {
	@apply block w-2 h-2 mt-1.5 mr-2 border-b-2 border-l-2 border-gray-400;
	content: '';
	transform: rotate(45deg);
}
.arrow:last-child::before {
	transform: rotate(-135deg);
}

.custom-w-table-144 {
	max-width: calc(100vw - 144px - 3rem);
}

.custom-w-table-360 {
	max-width: calc(100vw - 360px - 3rem);
}

.custom-input:not(:checked) {
	display: none;
}

.custom-input:checked::after {
	content: '';
	position: absolute;
	display: block;
	width: 17px;
	height: 17px;
	top: -2px;
	left: -2px;
	background: url('~@/assets/img/filter-tick.svg') no-repeat;
	z-index: 40;
}
.activeF {
	background: #3377ff;
}
</style>
